.testimonials-container {
  padding: 80px 100px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.testimonial-slider {
  width: 100%;
  color: #000;
}
.testimonial {
  padding: 20px;
}
.testimonial-content {
  padding: 30px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}
.testimonial-content .icon {
  color: #ac8558;
  font-size: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 992px) {
  .testimonials-container {
    padding: 50px 20px;
  }
}

.loginbtn,
.post-btn,
.location-btn {
  outline: transparent;
  border: transparent;
  color: #fff;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 70%;
  padding: 0 30px;
  background-color: #262626;
  transition: 0.2s all ease;
}
.location-btn {
  border-right: 0px;
}
.post-btn {
  background-color: #ac8558;
  margin-left: 10px;
  padding: 0 10px;
  color: #fff;
  font-weight: 500;
}
.post-btn:hover {
  opacity: 0.9;
}
.loginbtn:hover,
.location-btn:hover,
.post-btn:hover {
  background-color: #fff;
  color: #000;
}
.header-main {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  position: relative;
  width: 100%;
  background-color: #262626;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  position: relative;
}
.line {
  position: relative;
}
.line::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 70px;
  background-color: #ac8558;
}
.menus ul {
  height: 80px;
  display: flex;
  align-items: center;
}
.menus li {
  list-style: none;
  margin-right: 20px;
}
.menus a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.action-btns .sm-btn {
  background-color: #fff;
  font-size: 12px;
  border-radius: 2px;
  border: transparent;
  outline: transparent;
  padding: 5px 10px;
  margin-right: 4px;
}
.action-btns .icon {
  color: #000;
  font-size: 25px;
  cursor: pointer;
}
.login-btn {
  padding: 5px 15px;
  background-color: #fff;
  color: #333;
  font-weight: 500;
  outline: transparent;
  border: 0;
  &:hover {
    opacity: 0.8;
  }
}
.request-btn {
}
.post-btn.active {
  background-color: #fff;
  color: #000;
}
.upgrade-btn {
  padding: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  outline: transparent;
  margin: 0;
}
.help-menu {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  margin: 0 20px;
}
.menu-img {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.menu-img-container {
  position: relative;
}
.img-hover-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
}
/* ================================== */
.burger-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.burger-icon .icon {
  font-size: 40px;
  cursor: pointer;
  color: #fff;
}
.sub-header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.sub-header ul {
  height: 100%;
  display: flex;
  gap: 20px;
  justify-content: start;
  align-items: center;
}
.sub-header ul li {
  width: 100%;
  width: auto;
}
.sub-header ul li a {
  color: #333;
  position: relative;
}
.sub-header a.active {
  background-color: #eee;
  padding: 5px;
}

/* ================================= LOGOUT TIPPY  */
.logout-tippy {
  width: 100%;
  padding: 20px 10px;
}
.logout-tippy .icon {
  font-size: 20px;
  color: #333;
  margin: 5px 0;
}
.section-1,
.section-2,
.section-3 {
  cursor: pointer;
}
.section-2 {
  padding-bottom: 10px;
}
.section-3 {
  width: 100%;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 10px;
}
.section-3 button {
  padding: 10px;
  background-color: #26bd68;
  color: #fff;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
}

/* ================================= LOGOUT TIPPY ENDS */

/* ================================== */
@media screen and (max-width: 1212px) {
  .logo h3 {
    font-size: 20px;
  }
  .menus ul li a {
    font-size: 14px;
    display: inline-block;
  }
}
@media screen and (max-width: 1100px) {
  .logo h3 {
    font-size: 20px;
  }
  .logo img {
    width: 40px;
  }
  .logo p {
    font-size: 12px;
  }
  .menus ul li a {
    font-size: 12px;
    display: inline-block;
  }
}
@media screen and (max-width: 992px) {
  .header {
    height: 70px;
  }
  .header-main {
    padding: 0 20px;
  }
  .logo img {
    width: 200px;
  }
  .logo h3 {
    font-size: 25px;
  }
  .logo p {
    font-size: 14px;
    color: grey;
    margin: 0;
  }
  .sub-header {
    padding: 0 20px;
    overflow-x: scroll;
  }
  .sub-header ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
  }
  .sub-header ul li {
    width: auto;
  }
}

.sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100vh;
  transition: all 0.3s ease;
  z-index: 20;
}
.sidemenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 200px;
  padding-top: 100px !important;
  position: relative;
  background-color: #fff;
  padding: 0 40px;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 35px;
  color: #000;
}
.sidemenu ul li {
  margin: 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.sidemenu ul li a {
  color: #000;
  font-size: 20px;
}
.sidemenu .logout {
  color: #0c6b5b;
  cursor: pointer;
}
.sidemenu .logout:hover {
  color: #333;
}

.sidemenu .logout .icon {
  font-size: 25px;
  margin-left: 5px;
}
.sidemenu-img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
  border: 3px solid #26bd68;
  display: block;
  margin: 0 auto;
}
.active {
  left: 0;
}
.sidemenu-action-btn a {
  color: #fff;
  text-decoration: underline !important;
  margin-top: 40px !important;
}
/* @media screen and (max-width: 376px) {
  .sidemenu-container {
    width: 70%;
  }
  .sidemenu-action-btn .request-btn {
    font-size: 12px;
  }
} */

.search-container {
  position: fixed;
  top: -120%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: 0.5s all ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  z-index: 99;
  opacity: 0;
}
.search-container.active {
  top: 0;
  opacity: 1;
}
.search-tool {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-tool .icon {
  font-size: 35px;
  color: rgba(0, 0, 0, 0.7);
}
.search-tool input {
  width: 80%;
  padding: 15px;
  border: transparent;
  outline: transparent;
  border-bottom: 1px solid grey;
}
.search-box {
  width: 90%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
}
.search-product {
  width: 12% !important;
  margin: 0 10px 20px 10px !important;
}

@media screen and (max-width: 992px) {
  .search-container {
    top: 0;
    left: -100%;
    padding: 20px 0;
    z-index: 999;
    background-color: #fff !important;
  }
  .search-container.active {
    left: 0;
  }
  .search-container .icon {
    font-size: 25px;
  }
  .search-container input {
    padding: 10px;
  }
  .search-product {
    width: 40% !important;
    margin: 10px !important;
  }
}

.buy-sell-rent {
  width: 40%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
}
.h-card {
  padding: 20px 30px;
  background-color: #fff;
  margin-top: -50px;
}
/* HOME CATEGORIES  */
.home-categories-container {
  padding: 50px 150px;
  padding-bottom: 40px;
  background-color: #f5f7fb;
  display: flex;
  justify-content: space-between;
}
.categories {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 30%;
}
.categories .icon {
  color: #0c6b5b;
}
@media screen and (max-width: 992px) {
  .home-categories-container {
    padding: 50px 20px 20px 20px;
  }
}

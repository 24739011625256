.explore-city-container {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.explore-city-left {
  width: 30%;
  height: 500px;
  position: relative;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  padding: 50px;
  position: relative;
  overflow: hidden;
}

.map-img {
  position: absolute;
  left: -5%;
  bottom: -15%;
  opacity: 0.4;
  width: 100%;
  transform: rotate(180deg);
}
.explore-city-right {
  width: 70%;
  overflow: hidden;
}
.explore-city {
  height: 500px;
  text-align: center;
  flex-direction: column;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  background-color: #ac8558;
}
.explore-city.diff {
  background-color: #333;
}
.explore-city img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.explore-city::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
}
.explore-city-content {
  position: absolute;
  width: 100%;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #fff;
}
@media screen and (max-width: 992px) {
  .explore-city-left {
    width: 100%;
    height: 300px;
  }
  .explore-city-right {
    width: 100%;
  }
  .map-img {
    position: absolute;
    left: -20%;
    bottom: -70%;
    width: 150%;
    transform: rotate(180deg);
  }
}

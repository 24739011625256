.why-choose-us-container {
  padding: 50px 100px;
}
.why-choose-us-left,
.why-choose-us-right {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .why-choose-us-left,
  .why-choose-us-right {
    width: 100%;
  }
}

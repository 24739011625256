.add-property-container {
  padding: 50px;
}
.basic-info-property {
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
}
.parent-input {
  position: relative;
}
.plotAreaUnit {
  position: absolute;
  top: 0;
  right: 0;
}
.contact-us {
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  text-align: center;
}
.thumb-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  margin-top: 20px;
}
@media screen and (max-width: 992px) {
  .contact-us span {
    font-size: 14px;
  }
}

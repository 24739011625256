.agents-container {
  padding: 80px 100px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.agents-slider {
  width: 100%;
  color: #000;
}
.agent {
  padding: 20px;
}
.agent-img {
  height: 250px;
}
.agent-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.agent-content {
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 992px) {
  .agents-container {
    padding: 50px 20px;
  }
}

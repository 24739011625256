.dashboard-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-menu {
  width: 20%;
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  height: 100vh;
}
.dashboard-menu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-menu ul li {
  padding: 5px;
}
.dashboard-menu ul li.active {
  background-color: #fff;
}
.dashboard-menu ul li a {
  color: #333;
}

/* CONTENT  */
.dashboard-content {
  width: 80%;
}
.user-dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 40px;
  width: 100%;
}
.page-title {
  width: 100%;
}
.page-title h5 {
  font-weight: 600;
  color: #333;
}
.user-dashboard .dash-card {
  width: 23%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-dashboard .dash-card .icon {
  background-color: #ac8558;
  color: #fff;
  padding: 12px;
  font-size: 50px;
}
/* =============== MEDIA QUERY ===============  */
@media screen and (max-width: 992px) {
  .dashboard-container {
    padding: 20px 10px;
    position: relative;
  }
  .dashboard-menu {
    width: 100%;
  }
  .dashboard-content {
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
  }
  .user-dashboard {
    padding: 10px;
    flex-direction: column;
  }
  .user-dashboard .dash-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu {
    background-color: #262626;
    width: 100%;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
  }
  .filter-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu:hover {
    opacity: 0.8;
  }
  .mobile-menu .icon {
    font-size: 35px;
    padding-right: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 10px;
  }
  .db-menu-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    cursor: pointer;
    opacity: 0;
    z-index: -10;
  }
  .db-menu-backdrop.active {
    opacity: 1;
    z-index: 99;
  }
  .db-menu {
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    z-index: 9999;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease;
  }
  .db-menu.active {
    left: 0;
  }
  .db-menu li {
    margin: 20px;
  }
  .db-menu li a {
    color: #000;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #333;
  width: 100%;
  /* border: 1px solid red; */
}

.page-number {
  cursor: pointer;
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
}

.page-number.active {
  background-color: #007bff;
  color: #fff;
}
.disabled {
  opacity: 0.6;
}

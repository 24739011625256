.my-listings-container {
  width: 100%;
}
.my-listed-product-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}
.my-listed-product {
  display: flex;
  box-shadow: 2px -3px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.my-listed-product:hover {
  box-shadow: 2px -3px 15px rgba(0, 0, 0, 0.2);
}
.my-listed-product {
  width: 48%;
}
.my-listed-product-img {
  width: 40%;
  height: 200px;
  position: relative;
}
.my-listed-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.my-listed-product-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.p-tag {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: tomato;
  color: #fff;
  padding: 2px;
  font-size: 10px;
}
.camera-tag {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
}
.camera-tag .icon {
  color: #fff;
}
.p-tag.verified {
  background-color: #0c6b5b;
}
.my-listing-name {
  padding: 20px;
}
.my-listing-name span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to show */
  max-height: 54px; /* (font-size * number of lines) + line-height * (number of lines - 1) */
  font-size: 14px;
}
.my-listing-action {
  display: flex;
  padding: 10px 30px;
  justify-content: flex-end;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 15px;
}
.listing-views,
.listing-edit,
.listing-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}
.listing-views .icon,
.listing-edit .icon,
.listing-delete .icon {
  color: #ac8558;
  /* z-index: 99; */
}
@media screen and (max-width: 992px) {
  .my-listed-product {
    width: 100%;
  }
  .my-listing-name span {
    font-size: 12px;
  }
  .my-listed-product-img {
    height: 100%;
  }
}

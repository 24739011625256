.unsubscribe-container {
  background-color: #ebef29;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.unsubscribe-container button {
  padding: 8px 25px;
  margin-right: 10px;
  outline: transparent;
  border: transparent;
  background-color: #333;
  color: #fff;
  &:hover {
    background-color: white;
    color: #333;
  }
}

.product-page-container {
  padding: 50px 200px;
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 25px;
  background-color: #f7f7f7;
}
.product-filter-container {
  width: 25%;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.category button {
  padding: 4px 10px;
  font-size: 12px;
  margin: 5px;
  margin-left: 0;
  cursor: pointer;
  outline: transparent;
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 50px;
}
.category .icon {
  font-size: 16px;
  margin-right: 2px;
}
.category button:hover {
  background-color: #0c6b5b;
  color: #fff;
  opacity: 0.9;
}
.category button.active {
  color: #fff;
  background-color: #ac8558;
}
/* SLIDER  */
.slider {
  height: 10px;
  width: 100%;
  background-color: #ac8558;
  margin-top: 5px;
}
.slider .thumb {
  width: 23px;
  height: 23px;
  cursor: grab;
  top: -5px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ac8558;
}
/* STICKY  */
.sticky {
  position: sticky;
  top: 0;
}
/* BB PRODUCTS  */
.all-products-container {
  width: 65%;
}
.bb-product {
  background-color: #fff;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  gap: 0;
  margin-bottom: 10px;
  cursor: pointer;
}
/* border: 1px solid red; */
.bb-product-img {
  width: 40%;
  height: 250px;
}
.bb-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.bb-product-content {
  width: 60%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bb-product-price-sqft-bedrooms {
  display: flex;
  width: 100%;
}
.bb-price,
.bb-sqft {
  width: auto;
  padding: 0 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.bb-price {
  padding-left: 0;
}
.bb-price span:nth-child(1),
.bb-sqft span:nth-child(1),
.bb-bedrooms span:nth-child(1) {
  font-weight: bold;
  display: block;
}
.bb-bedrooms {
  padding-left: 20px;
}
.bb-highlights {
  overflow: hidden;
}
.bb-highlights span {
  background-color: #eee;
  padding: 4px;
  margin-left: 5px;
}
.bb-date-contact {
  background-color: #eee;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.bb-date-contact span:nth-child(3) {
  background-color: #262626;
  color: #fff;
  padding: 5px 20px;
  border-radius: 50px;
}

.budget-input {
  outline: transparent;
  padding: 0;
  border: 1px solid grey;
  width: 50%;
}
.budget-input-container {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

@media screen and (max-width: 992px) {
  .product-page-container {
    padding: 20px;
  }
  .product-filter-container {
    display: none;
  }
  .all-products-container {
    width: 100%;
  }
  .mobile-filter-container {
    min-height: 100vh;
    width: 100%;
    background-color: #fff;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 99;
    transition: 0.3s all ease;
    padding: 30px 10px;
    padding-top: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(105vh - 20px);
  }
  .mobile-filter-container.active {
    left: 0;
  }
  .mobile-filter-container .icon {
    font-size: 40px;
    display: block;
    margin: 0 auto;
    text-align: end;
  }
  .mobile-filter {
    width: 100%;
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .all-products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .bb-product {
    width: 49%;
  }
  .bb-product-content span {
    font-size: 12px;
  }
  .bb-product-img {
    width: 100%;
  }
  .bb-product-content {
    width: 100%;
    padding: 15px 0;
    padding-bottom: 0;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  .bb-product {
    width: 100%;
  }
  .bb-product-content span {
    font-size: 14px;
  }
}
@media screen and (max-width: 430px) {
  .bb-product-content span {
    font-size: 12px;
  }
}

.home {
  height: 80vh;
  background: url("../../img//home/herobg2.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  color: #fff;
}
.home h1 {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}
.home-left {
  width: 40%;
  z-index: 10;
}
.image-slider {
  overflow-x: scroll;
  border: 1px solid red;
  padding: 0;
}
.slide {
  padding: 0;
}

@media screen and (max-width: 992px) {
  .home {
    padding-left: 0;
    padding: 20px;
    position: relative;
  }
  .home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
  }
  .home h1 {
    font-size: 30px;
    margin-bottom: 5px;
  }
  .home p {
    font-size: 14px;
  }
  .home-left {
    width: 100%;
  }
}

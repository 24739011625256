.about-container {
  padding: 50px 20px;
}
.why-choose-bhiwandi-broker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 50px 20px;
}
.why-choose-bhiwandi-broker h2 {
  margin-bottom: 50px;
  position: relative;
}
.yline::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 15%;
  background-color: #ac8558;
}
.bhiwandi-broker {
  width: 23%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  min-height: 300px;
}
.bhiwandi-broker:hover {
  background-color: #ffe3c4;
}
.bhiwandi-broker h4 {
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
}
.bhiwandi-broker p {
  font-size: 14px;
  text-align: justify;
}
@media screen and (max-width: 992px) {
  .why-choose-mobi-heaven {
    padding: 0 20px;
  }
  .bhiwandi-broker {
    width: 100%;
    min-height: auto;
    margin-bottom: 30px !important;
  }
}

.admin-view-property-container {
}
.admin-product-info-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  color: #333;
  gap: 20px;
}

.cart-container {
  padding: 80px 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.cart-bag {
  width: 70%;
}
.cart-item-container {
  margin-top: 30px;
  /* border: 1px solid red; */
}
.cart-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}
.cart-item-img {
  width: 12%;
}
.cart-item-details,
.cart-item-quantity,
.cart-item-price {
  width: 20%;
}
.cart-item-delete {
  width: 5%;
  margin: 0 !important;
}
.cart-item-details {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.cart-item-details span {
  font-size: 14px;
}
.cart-item-details span:nth-child(1) {
  text-transform: uppercase;
}
.cart-item-price h5 {
  text-align: center;
  font-weight: 600;
}
.cart-item-img img {
  width: 100%;
  border-radius: 10px;
}
.cart-item-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px 20px;
  border-radius: 10px;
}
.cart-item-quantity span {
  font-size: 20px;
  margin: 0 10px;
}
.cart-item-quantity .icon {
  background-color: #333;
  color: #fff;
  border-radius: 100px;
  font-size: 30px;
}
.cart-item-delete {
  margin-top: -15px;
}
.cart-item-delete .icon:hover {
  color: red;
  cursor: pointer;
  margin-top: 0;
}
/* ================== CART TOTAL  */
.cart-total {
  width: 30%;
  background-color: #eee;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.cart-total h5 {
  font-weight: 400;
  margin: 0;
}
.cart-total .bold-text {
  font-weight: 500;
}
/* ================== CART TOTAL  */
/* ================== CART TOTAL  */
/* ================== CART MENU  */
.cart-menu-bag,
.cart-menu-total {
  width: 100%;
}
.cart-menu-item-img {
  width: 30%;
}
.cart-menu-item-details {
  width: 50%;
}
.cart-menu-item-quantity {
  width: 50%;
}
.cart-total {
  margin-top: 20px;
}
.apply-code-container {
  display: flex;
  margin-top: 10px;
}
.apply-code-container input {
  width: 100%;
  padding: 10px;
  outline: transparent;
  border: transparent;
}
.apply-code-container button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  margin-left: 5px;
}
/* ================== CART MENU  */

@media screen and (max-width: 992px) {
  .cart-container {
    padding: 50px 20px;
  }
  .cart-bag,
  .cart-total {
    width: 100%;
  }
  .cart-item-img {
    width: 30%;
  }
  .cart-item-details {
    width: 50%;
  }
  .cart-item-quantity {
    width: 50%;
  }
  .cart-total {
    margin-top: 20px;
  }
  .cart-item-img {
    width: 35%;
  }
  .cart-item-quantity {
    padding: 8px 10px;
  }
  .cart-menu-item-quantity {
  }
}

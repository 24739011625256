.product-info-container {
  padding: 50px 180px;
  display: flex;
  gap: 20px;
  background-color: #f7f7f7;
  align-items: start;
}
.product-info-container-left {
  width: 60%;
  padding: 15px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.product-info-container-left .main-img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.img-slider {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  height: 60px;
}
.img-slider img {
  width: 60px;
  height: 50px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
  &:hover {
    border: 2px solid #ac8558;
  }
}
.img-slider img.active {
  border: 2px solid #ac8558;
  scale: 1.05;
}
.product-info-container-right {
  width: 30%;
}
.product-info-container-right .icon {
  cursor: pointer;
  font-size: 35px;
}
.product-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.details {
  width: 50%;
  display: flex;
  margin-bottom: 5px;
}
.details span:nth-child(1) {
  width: 50%;
}
.product-highlights span {
  background-color: #eee;
  padding: 2px 6px;
  margin: 10px 10px 0 0;
  border-radius: 1px;
}
.product-price {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0px;
}
.more-details {
  padding-top: 0;
  padding: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0px;
}
.product-contact {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.product-contact input {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding-left: 5px;
}
.product-contact button {
  background-color: #ac8558;
  width: 100%;
  padding: 10px;
  color: #fff;
  border: transparent;
  outline: transparent;
}
.product-contact button:hover {
  opacity: 0.9;
}
.form-sticky {
  position: sticky;
  top: 0;
}

@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 30px 10px;
    flex-direction: column;
  }
  .product-info-container-left {
    width: 100%;
  }
  .product-info-container-left .main-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .img-slider {
    overflow-x: scroll;
  }
  .img-slider img {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover !important;
    border: none;
  }
  .img-slider img.active {
    border: none;
  }
  .product-info-container-right {
    width: 100%;
  }
  .details {
    width: 100%;
  }
}

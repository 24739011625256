.no-result-found {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-result-found img {
  width: 30%;
}
.no-result-found h5 {
  color: grey;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .no-result-found img {
    width: 100%;
  }
}

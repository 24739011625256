/* TestAdmin.css */
.photo-upload-container {
  display: flex;
  flex-wrap: wrap;
}
.dropzone {
  width: 100%;
}
.image-preview-container {
  width: 70%;
}
.edited-image-container {
  width: 40%;
  background-color: #eee;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-buttons {
  width: 100%;
}

.edited-image {
  overflow: hidden;
  border-radius: 20px;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.preview-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background-color: #fff;
  color: #333;
}
.preview-buttons button {
  background-color: #333;
  color: #fff;
  outline: transparent;
  border: transparent;
  padding: 10px 20px;
}
@media screen and (max-width: 992px) {
  .image-preview-container {
    width: 100%;
  }
  .edited-image-container {
    width: 100%;
    display: block;
    border: 1px solid red;
  }
  .edited-image {
    width: 100%;
  }
}

.learn-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
}
.learn-video-container iframe {
  width: 1000px;
  height: 560px;
  border-radius: 10px;
}

@media screen and (max-width: 786px) {
  .learn-video-container iframe {
    width: 100%;
    height: 200px;
  }
}

.transformation-container {
  padding: 50px 0;
  padding-bottom: 0;
}
.transformation-container h2 {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .transformation-container {
    padding: 0;
  }
  .transformation-container h2 {
    font-size: 16px;
  }
}

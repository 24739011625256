.support {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 999;
  cursor: pointer;
  span {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid #fff;
  }
  .icon {
    font-size: 60px;
    background-color: #ac8558;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 5px;
    color: #000;
  }
}
.footer-container {
  padding: 50px 250px;
  background-color: #262626;
  color: #fff;
  padding-top: 20px;
}
.footer-container img {
  width: 25%;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}
.footer-container h2 {
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 50px !important;
}
.footer-container h2::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 2px;
  width: 10%;
  background-color: #fff;
}
.footer-container .contact-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact-info .icon {
  font-size: 40px;
  color: #ac8558;
}
.contact-info-content ul {
  margin-top: 10px !important;
}
.contact-info-content li {
  margin: 5px 0;
}
.contact-info a {
  color: #fff;
}
.brown-btn {
  background-color: #ac8558;
}

@media screen and (max-width: 992px) {
  .footer-container {
    padding: 50px 20px;
    padding-top: 20px;
  }

  .footer-container img {
    width: 70%;
    margin-bottom: 0px;
  }
}

.tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.tabs .email,
.tabs .order-id {
  cursor: pointer;
  width: 50%;
  background-color: #eee;
  padding: 15px;
}
.tabs .email.active,
.tabs .order-id.active {
  background-color: #ebef29;
}

/* PRODUCTS  */
.products-container {
  padding: 0 150px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  background-color: #f5f7fb;
}
.product {
  width: 31%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.product:hover .pro-img img {
  scale: 1.1;
}
.pro-img {
  height: 200px;
  overflow: hidden;
  position: relative;
}
.pro-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
}
.pro-location {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 8;
  width: 95%;
}
.pro-location .icon {
  color: #ac8558;
}
.pro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: 1;
  transition: 0.3s all ease;
}
.product .bb-product-content {
  width: 100%;
  padding: 10px;
  gap: 10px;
}
.product .bb-product-content span {
  font-size: 14px;
}
.product .bb-sqft,
.product .bb-price,
.product .bb-bedrooms {
  padding: 0;
  border: 0;
  margin-right: 15px;
}

@media screen and (max-width: 992px) {
  .products-container {
    padding: 0px 20px;
    padding-bottom: 50px;
  }
  .product {
    width: 47%;
  }
}
@media screen and (max-width: 768px) {
  .products-container {
    padding: 0px 20px;
    padding-bottom: 50px;
  }
  .product {
    width: 47%;
  }
}
@media screen and (max-width: 600px) {
  .product {
    width: 100%;
  }
}
